<template>
  <div class="section">
    <div
      id="mapContainer1"
      class="map-container"
    />
    <new-search
      ref="searchRef"
      v-model="searchValue"
      search-type="titleName"
      :placeholder="placeholder"
      @getLists="searchList"
    />
    <!-- 地址微调与定位 -->
    <div class="address-list">
      <tab-composite
        ref="tabComposite"
        :tab-list="addressTabList"
        :refresh-enable="false"
        :load-enable="false"
        :fixed-height="fixedHeight"
        :need-btn="false"
        :need-first-tab="false"
        :is-refresh="false"
      >
        <error-page
          v-if="!addressList.length"
          slot="1"
        />
        <div
          v-else
          slot="1"
          class="list-wrapper"
        >
          <ul>
            <li
              v-for="(item, i) in addressList"
              :key="i"
              class="map-item border-bottom-1px"
              @click="selectCheckAddress(item)"
            >
              <div class="info">
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="address">
                  <span class="item-addr">{{ item.address }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </tab-composite>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import AMapLoader from '@amap/amap-jsapi-loader';
import errorPage from '_c/errorPage';
import newSearch from '_c/search/search-model';
import tabComposite from '_c/tab-composite';

import { areaList, cityList, provinceList } from '@/common/data/area';

const addressData = provinceList;
addressData.forEach(province => {
  province.children = cityList[province.value];
  province.children.forEach(city => {
    city.children = areaList[city.value];
  });
});

import {
  getAroundAddress,
} from '../../api/sign';
import config from '../../lib/config';

export default {
  components: {
    tabComposite,
    newSearch,
    errorPage
  },
  data() {
    return {
      searchValue: '',
      assignIndex: 0,
      map: null,
      timer: null,
      addressList: [],
      addressTabList: [
        {
          label: '打卡地址',
          value: 0
        }
      ],
      customerList: [],
      position: {
        lng: 120.24066,
        lat: 30.23907
      }, // 定位信息,
      keywords: '搜索关键字',
      index: 0,
      page: 1,
      pageSize: 10,
      nearbyList: [],
      checkAddr: '',
      requestData: {
        pageNum: 1,
        pageSize: 20,
        custCn: ''
      },
      fixedHeight: '',
      placeholder: '搜索附近定位地址'
    };
  },
  computed: {
    ...mapGetters('cust', ['aidList']),
    isPosition() {
      return this.$route.path === '/position-address'
    }
  },
  created() {
    this.geolocation = null;
  },
  mounted() {
    this.loadMap();
    this.countHeight();
  },
  updated() {
    this.countHeight();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapMutations('sign', {
      setCheckAddress: 'SET_CHECK_ADDRESS',
      setAddress: 'SET_ADDRESS'
    }),
    countHeight() {
      this.$nextTick().then(() => {
        this.fixedHeight = 300 + '';
      });
    },
    searchList(value) {
      this.getNearByAddress(value);
    },
    reLocation() {
      this.geolocation.getCurrentPosition((status) => {
        if (status === 'complete') {

        }
      });
    },
    selectCheckAddress(item) {
      if (this.isPosition) {
        // 匹配地址code
        let addrProvince = ''
        provinceList.forEach((itemA) => {
          if (itemA.text === item.pname) addrProvince = itemA.value
        })
        let addrCity = '';
        cityList[addrProvince].forEach((itemA) => {
          if (itemA.text === item.cityname) addrCity = itemA.value
        });
        let addrArea = '';
        areaList[addrCity].forEach((itemA) => {
          if (itemA.text === item.adname) addrArea = itemA.value
        });
        const addressInfo = {
          province: item.pname,
          city: item.cityname,
          area: item.adname,
          addrStreet: item.address,
          addrProvince: addrProvince,
          addrCity: addrCity,
          addrArea: addrArea
        }
        // 地址定位
        this.setAddress(addressInfo)
      } else {
        // 地址微调
        this.setCheckAddress({ name: item.name, address: (item.pname !== null ? item.pname : '') + (item.cityname !== null ? item.cityname : '') + (item.adname !== null ? item.adname : '') + item.address, location: item.location });
      }
      this.confirmCheckAddress();
    },
    // 获取附近定位地址
    getNearByAddress(value) {
      getAroundAddress(this.position.lng, this.position.lat, '50', value).then(
        res => {
          if (res.flag) {
            if (res.data) {
              const data = JSON.parse(res.data);
              this.addressList = data.pois;
              // this.addressList.forEach(item => {
              //   const replaceReg = new RegExp(value, 'ig')
              //   const replaceString = `<span style="color: #00A4FF">${value}</span>`
              //   item.custCnLight = item.custCn.replace(replaceReg, replaceString)
              // })
            }
          } else {
            this.$showToast(res.errorMsg);
          }
        }
      );
    },
    confirmCheckAddress() {
      this.$router.go(-1);
    },
    loadMap() {
      const self = this;
      // 解析定位结果
      function onComplete(AMap, result) {
        self.position = result.position;
        self.checkAddr = result.formattedAddress || '';
        self.getNearByAddress();
      }
      // 解析定位错误信息
      function onError(data) {
        return data;
      }

      AMapLoader.load({
        key: config.MAP_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [] // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2' // Loca 版本，缺省 1.3.2
        }
      })
        .then(AMap => {
          const map = new AMap.Map('mapContainer1', { resizeEnable: true });

          AMap.plugin('AMap.Geolocation', () => {
            this.geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, // 是否使用高精度定位，默认:true
              timeout: 10000, // 超过10秒后停止定位，默认：5s
              showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
              showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
              showButton: true, // 显示定位按钮，默认：true
              buttonPosition: 'RT', // 定位按钮停靠位置，默认：'LB'，左下角
              buttonOffset: new AMap.Pixel(16, 16), // 定位按钮位置
              panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
              zoomToAccuracy: true // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            });

            map.addControl(this.geolocation);
            this.geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                onComplete(AMap, result);
              } else {
                onError(result);
              }
            });
          });
        });
    },
    // 搜索规则
    // ruleFunc(value) {
    //   if (value === '') return false;
    //   if (value.length < 4) {
    //     this.$showToast('不得少于四个字');
    //     return false;
    //   }
    //   return true;
    // }
  }
};
</script>
<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 240px;
}
.address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 16px;
  font-weight: 400;
  line-height: 1.5;
  background: $color-FFF;
  .text {
    color: $color-333;
    font-size: 15px;
    text-align: left;
  }
  .button {
    color: $color-theme;
    font-size: 14px;
  }
}

.search-box {
  padding: 15px;
  background: $color-FFF;
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 34px;
    padding: 0 10px;
    color: $color-CCC;
    font-size: 14px;
    background: $color-F4;
    border: 1px solid $color-E6;
    border-radius: 17px;
    .icon-joyo {
      margin-right: 10px;
    }
  }
}

.list-wrapper {
  padding-left: 16px;
  background: $color-FFF;

  .map-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    .edit-cust {
      flex:0 0 20px;
      width: 20px;
      margin-right: 16px;
      margin-left:12px ;
      color: #C6C7CC;
      font-size: 20px;
      text-align: center;
    }

    .info {
      flex: 1;
      margin-right: 48px !important;
      text-align: left;
      .name {
        margin-bottom: 4px;
        color: #23252E;
        font-size: 15px;
        line-height: 20px;
      }
      .address {
        display: flex;
        color: #999BA3;
        font-size: 12px;
        line-height: 16px;
        .item-addr{
          flex: 1;
          @extend .uniline-ellipsis;
        }
      }
    }
  }
}
</style>
